import TextField from '@mui/material/TextField';
import { config } from '../../config/Constants';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import React, { useState} from 'react';
import Swal from 'sweetalert2';
import Axios from 'axios';
import './register.css';


const Register = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [company, setCompany] = useState();
    const [formUsed, setFormUsed] = useState();
    const [formUserNames, setFormUsernames] = useState();

    const back = () => {
        navigate('/');
    }

    const submit = () => {
        Axios.post(config.url.REGISTER, {
            firstName: firstName,
            lastName: lastName,
            userName: username,
            password: password,
            email: email,
            company: company,
            formUsed: formUsed,
            formUserNames: formUserNames
        }).then((response) => {
          if (response.data.status == 'Success') {
            Swal.fire({
              icon: 'success',
              title: 'Registeration Successful',
              text: response.data.message,
            })
          }else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response.data.message,
            })
          }

        })
    }

  return (
    <div className="regContainer">
    <div className="regFormContainer">
      <div className="regFormTop">
        <div className="regFormTitle">
          <h1>Form Tracking Registration</h1>
        </div>
        <div className='regFormNote'>
            <p>Clients may use a variety of forms. To receive tracking on form submissions, please fill out the below fields:</p>
        </div>
      </div>
      <div className="regFormMid">
          <div className="row">
            <div className="refinputbox ">
                <div>
                  <TextField 
                  id="standard-basic" 
                  label="First Name" 
                  variant="standard" 
                  type="text" 
                  onChange={e => setFirstName(e.target.value)}/>
                </div>
            </div>
            <div className="refinputbox ">
                <div>
                  <TextField 
                    id="standard-basic" 
                    label="Last Name" 
                    variant="standard" 
                    type="text" 
                    onChange={e => setLastName(e.target.value)}/>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="refinputbox ">
                <div>
                  <TextField 
                    id="standard-basic" 
                    label="Username" 
                    variant="standard" 
                    type="text" 
                    onChange={e => setUsername(e.target.value)}/>
                </div>
            </div>
            <div className="refinputbox ">
                <div>
                  <TextField 
                     id="standard-basic" 
                     label="Password" 
                     variant="standard" 
                     type="text" 
                    onChange={e => setPassword(e.target.value)}/>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="refinputbox ">
                <div>
                  <TextField 
                    id="standard-basic" 
                    label="Email" 
                    variant="standard" 
                    type="text" 
                    onChange={e => setEmail(e.target.value)}/>
                </div>
            </div>
            <div className="refinputbox ">
                <div>
                  <TextField 
                    id="standard-basic" 
                    label="Company" 
                    placeholder='e.g., GTMS' 
                    variant="standard" 
                    type="text" 
                    onChange={e => setCompany(e.target.value)}/>
                </div>
            </div>
        </div>
        <div className="rowForm">
            <div className="refforminputbox ">
                <div>
                  <TextField 
                    style={{ width: "400px" }} 
                    id="standard-basic" 
                    label="Forms Used" 
                    placeholder='e.g., Mexico, International, Domestic, POD' 
                    variant="standard" 
                    type="text" 
                    onChange={e => setFormUsed(e.target.value)}/>
                </div>
            </div>
        </div>
        <div className="rowForm">
            <div className="refforminputbox ">
                <div>
                  <TextField 
                  style={{ width: "400px" }} 
                  id="standard-basic" 
                  label="Username for forms" 
                  placeholder='e.g., john.doe' 
                  variant="standard" 
                  type="text" 
                  onChange={e => setFormUsernames(e.target.value)}/>
                </div>
            </div>
        </div>
      </div>
      <div className="regFormbottom">
        <div className="regSubmit">
          <Button variant="contained" onClick={submit}>Submit</Button>
        </div>
        <div className="regBack">
          <Button variant="contained" onClick={back}>Back</Button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Register