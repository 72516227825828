import Axios from 'axios';
import React, { useState, useEffect} from 'react'
import {config} from '../../config/Constants';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.form_id}
        </TableCell>
        <TableCell style={{ maxWidth: 100 }}>
          {row && row.list && row.list[0] && row.list[0].list && row.list[0].list[0]
            ? row.list[0].list[0].PO_NUMBER
            : ''}
        </TableCell>
        <TableCell>{row.STATUS ? row.STATUS : "Pending"}</TableCell>
        <TableCell>{row.submit_user}</TableCell>
        <TableCell>{row.submit_date}</TableCell>
        <TableCell >{row.LAST_UPDATED ? row.LAST_UPDATED : '-'}</TableCell>
      </TableRow>
      <TableRow style={{backgroundColor: '#f8f8f8'}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Form Submission
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell><Typography fontWeight="bold">Form ID:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Req. Name:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Req. Contact:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Req. Email:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Req. Ref #1:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Req. Ref #2:</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.list.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell align="center" component="th" scope="row">
                        {historyRow.form_id}
                      </TableCell>
                      <TableCell align="center">{historyRow.request_name}</TableCell>
                      <TableCell align="center">{historyRow.req_contact}</TableCell>
                      <TableCell align="center">{historyRow.req_email}</TableCell>
                      <TableCell align="center">{historyRow.req_ref_1}</TableCell>
                      <TableCell align="center">{historyRow.req_ref_2}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography fontWeight="bold">Origin Name:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin Address #1:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin Address #2:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin Contact:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin Phone:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin Email:</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.list.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell align="center" component="th" scope="row">
                        {historyRow.origin_name}
                      </TableCell>
                      <TableCell align="center">{historyRow.origin_add_1}</TableCell>
                      <TableCell align="center">{historyRow.origin_add_2}</TableCell>
                      <TableCell align="center">{historyRow.origin_contact}</TableCell>
                      <TableCell align="center">{historyRow.origin_phone}</TableCell>
                      <TableCell align="center">{historyRow.origin_email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography fontWeight="bold">Origin Port:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin Pickup:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin Country:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin State:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin City:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Origin Zip:</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.list.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell align="center" component="th" scope="row">
                        {historyRow.origin_port}
                      </TableCell>
                      <TableCell align="center">{historyRow.pick_up_date}</TableCell>
                      <TableCell align="center">{historyRow.origin_country}</TableCell>
                      <TableCell align="center">{historyRow.origin_state}</TableCell>
                      <TableCell align="center">{historyRow.origin_city}</TableCell>
                      <TableCell align="center">{historyRow.origin_postal}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography fontWeight="bold">CNEE. Name:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE. Address #1:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE. Address #2:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE. Contact:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE. Phone:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE. Email:</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.list.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell align="center" component="th" scope="row">
                        {historyRow.consignee_name}
                      </TableCell>
                      <TableCell align="center">{historyRow.consignee_add_1}</TableCell>
                      <TableCell align="center">{historyRow.consignee_add_2}</TableCell>
                      <TableCell align="center">{historyRow.consignee_contact}</TableCell>
                      <TableCell align="center">{historyRow.consignee_phone}</TableCell>
                      <TableCell align="center">{historyRow.consignee_email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography fontWeight="bold">CNEE: Port:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE: Pickup:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE: Country:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE: State:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE: City:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CNEE: Zip:</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.list.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell align="center" component="th" scope="row">
                        {historyRow.dest_port}
                      </TableCell>
                      <TableCell align="center">{historyRow.delivery_date}</TableCell>
                      <TableCell align="center">{historyRow.consignee_country}</TableCell>
                      <TableCell align="center">{historyRow.consignee_state}</TableCell>
                      <TableCell align="center">{historyRow.consginee_city}</TableCell>
                      <TableCell align="center">{historyRow.consignee_postal}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography fontWeight="bold"></Typography></TableCell>
                    <TableCell><Typography fontWeight="bold"></Typography></TableCell>
                    <TableCell><Typography fontWeight="bold"></Typography></TableCell>
                    <TableCell><Typography fontWeight="bold"></Typography></TableCell>
                    <TableCell><Typography fontWeight="bold"></Typography></TableCell>
                    <TableCell><Typography fontWeight="bold"></Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography fontWeight="bold">Pallets:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Pieces:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Package:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Sku:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Description:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Po Number:</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.list.map((historyRow) =>
                    historyRow.list.map((item) => (
                      <TableRow key={item.ID}>
                        <TableCell align="center" component="th" scope="row">
                          {item.PALLET_NUMBER}
                        </TableCell>
                        <TableCell align="center">{item.PIECES}</TableCell>
                        <TableCell align="center">{item.PACKAGE}</TableCell>
                        <TableCell align="center">{item.SKU_NUMBER}</TableCell>
                        <TableCell align="center">{item.DESCRIPTION}</TableCell>
                        <TableCell align="center">{item.PO_NUMBER}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography fontWeight="bold">Length:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Width:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Height:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Weight:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">CBM:</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Class:</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.list.map((historyRow) =>
                    historyRow.list.map((item) => (
                      <TableRow key={item.ID}>
                        <TableCell align="center" component="th" scope="row">
                          {item.LENGTH}
                        </TableCell>
                        <TableCell align="center">{item.WIDTH}</TableCell>
                        <TableCell align="center">{item.HEIGHT}</TableCell>
                        <TableCell align="center">{item.WEIGHT}</TableCell>
                        <TableCell align="center">{item.CBM}</TableCell>
                        <TableCell align="center">{item.CLASS}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>

              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({search}) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);


  useEffect(() => {
  const username = localStorage.getItem('gtms-formtracker-username');
  const company = localStorage.getItem('gtms-formtracker-company');
    Axios.post(config.url.TRACKING_INFO, {"username": username, "company": company}, {
      headers: {"Content-Type": "application/json"}
    }).then(response => {
      setData(response.data);
      setFilteredData(response.data);
    })
  }, []);


  useEffect(() => {
    if (search.length !== 0) {
      const filtered = data.filter(row => {
        const found = row.list.some(list => list.list.some(item => {
          const po = item.PO_NUMBER;
          return po && po.toLowerCase().includes(search[0].toLowerCase());
        }));
        return (row.form_id && row.form_id.toLowerCase().includes(search[0].toLowerCase())) ||
        found;
        //(po && po.toLowerCase().includes(search[0].toLowerCase()));
      })
      setFilteredData(filtered)
    } else {
      setFilteredData(data)
      //console.log(data[0].list[0].list[0].PO_NUMBER)
    }
  }, [search, data]);

  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow style={{backgroundColor: '#fcba03'}}>
            <TableCell />
            <TableCell><Typography fontSize={20} fontWeight="bold">Form ID:</Typography></TableCell>
            <TableCell><Typography fontSize={20} fontWeight="bold">PO Number:</Typography></TableCell>
            <TableCell><Typography fontSize={20} fontWeight="bold">Status:</Typography></TableCell>
            <TableCell><Typography fontSize={20} fontWeight="bold">Submit By:</Typography></TableCell>
            <TableCell><Typography fontSize={20} fontWeight="bold">Submission Date:</Typography></TableCell>
            <TableCell><Typography fontSize={20} fontWeight="bold">Last Updated Status:</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row, index) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
