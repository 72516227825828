import Search from '../../components/search/Search';
import Table from '../../components/table/Table';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import './tracker.css';

const Tracker = () => {
  const navigate = useNavigate();
  const [search, setSearching] = useState([]);

  const logout = () => {
    localStorage.removeItem('gtms-formtracker-username');
    localStorage.removeItem('gtms-formtracker-jwt-auth');
    localStorage.removeItem('gtms-formtracker-company');
    navigate('/');
  }

  return (
    <div className='container'>
      <div className="trackerLogOut">
        <div className="trackingLogoutLable">
          <h4>Done Tracking? </h4>
        </div>
        <div className="trackingLogOutButton">
          <Button size='small' variant="outlined" onClick={logout}>Log Out</Button>
        </div>
      </div>
      <div className="trackerContainer">
        <div className="trackerTop">
          <div className="trackerTitle">
            <h1>Form Tracker</h1>
          </div>
        </div>
        <div className="trackerMid">
          <div className="trackerSearch">
            <Search setSearching={setSearching}/>
          </div>
          <div className="trackerTable">
            <Table search={search}/>
          </div>
        </div>
        <div className="trackerBottom"></div>
      </div>
    </div>
  )
}

export default Tracker