const prod = {
    url: {
        TRACKING_INFO:  'https://gtms-formtracker-be.azurewebsites.net/tracker/getforminfo',
        LOGIN:          'https://gtms-formtracker-be.azurewebsites.net/auth/login',
        REGISTER:       'https://gtms-formtracker-be.azurewebsites.net/register/newuser',
        VERIFY_TOKEN:   'https://gtms-formtracker-be.azurewebsites.net/auth/verify'
    }
}

const dev = {
    url: {
        TRACKING_INFO:  'http://localhost:3001/tracker/getforminfo',
        LOGIN:          'http://localhost:3001/auth/login',
        REGISTER:       'http://localhost:3001/register/newuser',
        VERIFY_TOKEN:   'http://localhost:3001/auth/verify'
    }
   };


//export const config = process.env.NODE_ENV === 'development' ? dev : prod;
export const config = prod;
