import Image from '../../components/logo/Logo';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { config } from '../../config/Constants';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import Swal from 'sweetalert2';
import Axios from 'axios';
import './login.css';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitButton, setSubmitButton] = useState(false);
    const [registerButton, setRegisterButton] = useState(false);

    const login = () => {
        setSubmitButton(true);
        Axios.post(config.url.LOGIN, {
          username: username,
          password: password,
        }).then((response) => {
          localStorage.setItem('gtms-formtracker-username', response.data.username);
          localStorage.setItem('gtms-formtracker-jwt-auth', response.data.accessToken);
          localStorage.setItem('gtms-formtracker-company', response.data.company);
          
          Axios.post(config.url.VERIFY_TOKEN, {
            token: response.data.accessToken,
          }).then((response) => {
            if (response.data.verified === true) {
              navigate('/formtracker');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Incorrect username or password!',
                  })
              setSubmitButton(false);
              navigate('/');
            }
          });
        });
    };


    const register = () => {
        setRegisterButton(false);
        navigate('/register');
    }


  return (
    <div className='loginContainer'>
        <div className='login'>
            <div className='title'>
                <Image/>
                <h1>Form Tracker</h1>
            </div>
            <div className='username'>
                <TextField id="outlined-basic" label="Username" size="small" variant="outlined" onChange={e => setUsername(e.target.value)} />
            </div>
            <div className='password'>
                <TextField id="outlined-basic" label="Password" type="password" size="small" variant="outlined" onChange={e => setPassword(e.target.value)}/>
            </div>
            <div className='submit'>
                <div className="loginButton">
                    <Button disabled={submitButton} variant="contained" onClick={login}>Log In</Button>
                </div>
                <div>
                    <Button disabled={registerButton} variant="contained" onClick={register}>Register</Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login