import React, { useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';

const Search = ({setSearching}) => {

  const handleChange = (e) => {
    if (e.key === 'Enter') {
      let searching = e.target.value;
      setSearching([searching]);
    }
  }

  return (
    <div className='search'>
        <TextField
        sx={{
          width: 500
        }}
          margin="normal" 
          id="forSearch"
          label="Press Enter to Search: (Search on Form ID)"
          variant="outlined"
          onKeyDown={handleChange}
          />
    </div>
  )
}

export default Search