import {Route,  BrowserRouter, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import Tracker from "./pages/tracker/Tracker";
import Register from "./pages/register/Register";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes path="/">
          <Route index element={<Login/>}/>
          <Route path="formtracker">
            <Route index element={<Tracker/>}/>
          </Route>
          <Route path="register">
            <Route index element={<Register/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
